var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Hobi")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Hobbi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.hobby),callback:function ($$v) {_vm.$set(_vm.formBody, "hobby", $$v)},expression:"formBody.hobby"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Keahlian Yang Dimiliki")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Keahlian Yang Dimiliki"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.other_skills_possessed),callback:function ($$v) {_vm.$set(_vm.formBody, "other_skills_possessed", $$v)},expression:"formBody.other_skills_possessed"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Cita-Cita")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Cita-Cita"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.future_goals),callback:function ($$v) {_vm.$set(_vm.formBody, "future_goals", $$v)},expression:"formBody.future_goals"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Motto Hidup")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Motto Hidup"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.life_motto),callback:function ($$v) {_vm.$set(_vm.formBody, "life_motto", $$v)},expression:"formBody.life_motto"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Makanan Favorit")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Makanan Favorit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.favorite_food),callback:function ($$v) {_vm.$set(_vm.formBody, "favorite_food", $$v)},expression:"formBody.favorite_food"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Minuman Favorit")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Minuman Favorit"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.favorite_drink),callback:function ($$v) {_vm.$set(_vm.formBody, "favorite_drink", $$v)},expression:"formBody.favorite_drink"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Link Instagram")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Link Instagram","rules":"isURL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"https://","error-messages":errors,"solo":""},model:{value:(_vm.formBody.instagram),callback:function ($$v) {_vm.$set(_vm.formBody, "instagram", $$v)},expression:"formBody.instagram"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Link Facebook")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Link Facebook","rules":"isURL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"https://","error-messages":errors,"solo":""},model:{value:(_vm.formBody.facebook),callback:function ($$v) {_vm.$set(_vm.formBody, "facebook", $$v)},expression:"formBody.facebook"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Link Linkedin")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Link Linkedin","rules":"isURL"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"https://","error-messages":errors,"solo":""},model:{value:(_vm.formBody.linkedin),callback:function ($$v) {_vm.$set(_vm.formBody, "linkedin", $$v)},expression:"formBody.linkedin"}})]}}])})],1)],1),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.isLoading},on:{"click":_vm.handleUpdate}},[_vm._v(" Simpan ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }