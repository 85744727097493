import router from './router'
import store from './store'
import {
    getToken,
} from '@/utils/cookies' // get token from cookie

const whiteList = ['/login', '/auth-redirect'] // no redirect whitelist

router.beforeEach(async (to, from, next) => {
    // determine whether the user has logged in
    const hasToken = getToken()
    if (hasToken) {
        if (to.path === '/login') {
            // if is logged in, redirect to the home page
            await next({
                path: '/',
            })
        } else {
            // determine whether the user has obtained his permission roles through getInfo
            const hasRoles = await store.getters['user/roles'] && store.getters['user/roles'].length > 0
            if (hasRoles) {
                next()
            } else {
                try {
                    // get user info
                    // note: roles must be a object array! such as: ['admin'] or ,['developer','editor']
                    const {
                        roles,
                    } = await store.dispatch('user/getInfo')
                    await store.dispatch('user/getListMenuUser')
                    // generate accessible routes map based on roles
                    const accessRoutes = await store.dispatch('permission/generateRoutes', roles)
                    // dynamically add accessible routes
                    await router.addRoutes(accessRoutes)
                    // hack method to ensure that addRoutes is complete
                    // set the replace: true, so the navigation will not leave a history record
                    await next({
                        ...to,
                        replace: true,
                    })
                } catch (error) {
                    // remove token and go to login page to re-login
                    await store.dispatch('user/resetToken')
                    await next(`/login?redirect=${to.path}`)
                }
            }
        }
    } else {
        /* has no token */

        if (whiteList.indexOf(to.path) !== -1) {
            // in the free login whitelist, go directly
            await next()
        } else {
            // other pages that do not have permission to access are redirected to the login page.
            await next(`/login?redirect=${to.path}`)
        }
    }
})
