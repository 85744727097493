var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"70%"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-7"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Email")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","error-messages":errors,"solo":""},model:{value:(_vm.formBody.email),callback:function ($$v) {_vm.$set(_vm.formBody, "email", $$v)},expression:"formBody.email"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Username")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"rules":_vm.allowSpaces,"error-messages":errors,"solo":""},model:{value:(_vm.formBody.username),callback:function ($$v) {_vm.$set(_vm.formBody, "username", $$v)},expression:"formBody.username"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Nama")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"5","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nama Depan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Nama Depan","type":"text","error-messages":errors,"solo":""},model:{value:(_vm.formBody.first_name),callback:function ($$v) {_vm.$set(_vm.formBody, "first_name", $$v)},expression:"formBody.first_name"}})]}}])})],1),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"5","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nama Belakang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Nama Belakang","type":"text","error-messages":errors,"solo":""},model:{value:(_vm.formBody.last_name),callback:function ($$v) {_vm.$set(_vm.formBody, "last_name", $$v)},expression:"formBody.last_name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("TTL")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"5","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Tempat Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Tempat Lahir","type":"text","error-messages":errors,"solo":""},model:{value:(_vm.formBody.birth_place),callback:function ($$v) {_vm.$set(_vm.formBody, "birth_place", $$v)},expression:"formBody.birth_place"}})]}}])})],1),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"5","sm":"12"}},[_c('input-date-picker',{attrs:{"format-date":_vm.formatDate,"required":true,"date-value":_vm.formBody.birth_date,"value-date":_vm.formBody.birth_date},on:{"update:valueDate":function($event){return _vm.$set(_vm.formBody, "birth_date", $event)},"update:value-date":function($event){return _vm.$set(_vm.formBody, "birth_date", $event)},"changeDate":function($event){_vm.formBody.birth_date = $event}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"4","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Jenis Kelamin")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Jenis Kelamin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"error-messages":errors,"row":""},model:{value:(_vm.formBody.gender),callback:function ($$v) {_vm.$set(_vm.formBody, "gender", $$v)},expression:"formBody.gender"}},[_c('v-radio',{attrs:{"label":"Pria","value":"Pria"}}),_c('v-radio',{attrs:{"label":"Wanita","value":"Wanita"}})],1)]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Divisi")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Divisi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.divisiList,"error-messages":errors,"return-object":true,"item-value":"id","item-text":"name_satuan_kerja","menu-props":"auto","solo":""},on:{"change":_vm.handleChangeDivisi},model:{value:(_vm.formBody.divisiObject),callback:function ($$v) {_vm.$set(_vm.formBody, "divisiObject", $$v)},expression:"formBody.divisiObject"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Jabatan")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Jabatan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.jabatanList,"error-messages":errors,"return-object":true,"item-value":"id","item-text":"name_jabatan","menu-props":"auto","solo":""},on:{"change":_vm.handleChangeJabatan},model:{value:(_vm.formBody.jabatanObject),callback:function ($$v) {_vm.$set(_vm.formBody, "jabatanObject", $$v)},expression:"formBody.jabatanObject"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Kategori Pengelola")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Kategori Pengelola"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.managerCategory,"error-messages":errors,"menu-props":"auto","clearable":"","solo":""},model:{value:(_vm.formBody.manager_category),callback:function ($$v) {_vm.$set(_vm.formBody, "manager_category", $$v)},expression:"formBody.manager_category"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Tanggal Bergabung")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Tanggal Bergabung"}},[_c('input-date-picker',{attrs:{"format-date":_vm.formatDateTime,"date-value":_vm.formBody.join_date,"value-date":_vm.formBody.join_date},on:{"update:valueDate":function($event){return _vm.$set(_vm.formBody, "join_date", $event)},"update:value-date":function($event){return _vm.$set(_vm.formBody, "join_date", $event)},"changeDate":function($event){_vm.formBody.join_date = $event}}})],1)],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Pegawai Aktif ?")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Pegawai Aktif ?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"error-messages":errors,"inset":""},model:{value:(_vm.formBody.is_active),callback:function ($$v) {_vm.$set(_vm.formBody, "is_active", $$v)},expression:"formBody.is_active"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"block":""},on:{"click":_vm.handleCancel}},[_vm._v(" Batal ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.isLoading},on:{"click":_vm.handleSave}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }