import Layout from '@/layout'

const dayoffRouter = {
    path: '/dayoff',
    component: Layout,
    redirect: 'dayoff',
    meta: {
        icon: 'mdi-file-document',
        roles: ['admin'],
    },
    active: false,
    children: [{
        path: '/dayoff',
        component: () => import('@/views/dayoff/list'),
        meta: {
            title: 'List Izin Pegawai',
            icon: 'mdi-file-document',
            noCache: true,
            affix: true,
        },
    }],
}

export default dayoffRouter
