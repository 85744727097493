var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"8"}},[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Email")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"email","error-messages":errors,"solo":""},model:{value:(_vm.formBody.email),callback:function ($$v) {_vm.$set(_vm.formBody, "email", $$v)},expression:"formBody.email"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Username")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Username","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"rules":_vm.allowSpaces,"error-messages":errors,"solo":""},model:{value:(_vm.formBody.username),callback:function ($$v) {_vm.$set(_vm.formBody, "username", $$v)},expression:"formBody.username"}})]}}])})],1)],1),_vm._l((_vm.formBody.account_identity),function(item,index){return _c('v-row',{key:index},[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Jenis Nomor Identitas")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"3","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Jenis Nomor Identitas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.identityNumberTypeList,"error-messages":errors,"item-value":"id_number_type","item-text":"id_number_type","menu-props":"auto","solo":""},model:{value:(item.type_identity),callback:function ($$v) {_vm.$set(item, "type_identity", $$v)},expression:"item.type_identity"}})]}}],null,true)})],1),_c('v-col',{staticClass:"px-0 center py-6",attrs:{"cols":"12","md":"1","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Nomor")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nomor Identitas","rules":"numeric|required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##################'),expression:"'##################'"}],attrs:{"placeholder":"Nomor Identitas","error-messages":errors,"solo":""},model:{value:(item.number),callback:function ($$v) {_vm.$set(item, "number", $$v)},expression:"item.number"}})]}}],null,true)})],1)],1)}),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Nama")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"5","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nama Depan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Nama Depan","type":"text","error-messages":errors,"solo":""},model:{value:(_vm.formBody.first_name),callback:function ($$v) {_vm.$set(_vm.formBody, "first_name", $$v)},expression:"formBody.first_name"}})]}}])})],1),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"5","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nama Belakang","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Nama Belakang","type":"text","error-messages":errors,"solo":""},model:{value:(_vm.formBody.last_name),callback:function ($$v) {_vm.$set(_vm.formBody, "last_name", $$v)},expression:"formBody.last_name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("TTL")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"5","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Tempat Lahir","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"placeholder":"Tempat Lahir","type":"text","error-messages":errors,"solo":""},model:{value:(_vm.formBody.birth_place),callback:function ($$v) {_vm.$set(_vm.formBody, "birth_place", $$v)},expression:"formBody.birth_place"}})]}}])})],1),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"5","sm":"12"}},[_c('input-date-picker',{attrs:{"format-date":_vm.formatDate,"required":true,"date-value":_vm.formBody.birth_date,"value-date":_vm.formBody.birth_date},on:{"update:valueDate":function($event){return _vm.$set(_vm.formBody, "birth_date", $event)},"update:value-date":function($event){return _vm.$set(_vm.formBody, "birth_date", $event)},"changeDate":function($event){_vm.formBody.birth_date = $event}}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"4","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Jenis Kelamin")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Jenis Kelamin","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"error-messages":errors,"row":""},model:{value:(_vm.formBody.gender),callback:function ($$v) {_vm.$set(_vm.formBody, "gender", $$v)},expression:"formBody.gender"}},[_c('v-radio',{attrs:{"label":"Pria","value":"Pria"}}),_c('v-radio',{attrs:{"label":"Wanita","value":"Wanita"}})],1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"4","sm":"12"}},[_c('label',[_vm._v("Golongan Darah")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Golongan Darah"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.listBloodType,"error-messages":errors,"menu-props":"auto","solo":""},model:{value:(_vm.formBody.blood_type),callback:function ($$v) {_vm.$set(_vm.formBody, "blood_type", $$v)},expression:"formBody.blood_type"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"4","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Status Pernikahan")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"8","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Status Pernikahan","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"mt-0",attrs:{"error-messages":errors,"row":""},model:{value:(_vm.formBody.marital_status),callback:function ($$v) {_vm.$set(_vm.formBody, "marital_status", $$v)},expression:"formBody.marital_status"}},[_c('v-radio',{attrs:{"label":"Belum Menikah","value":"Belum Menikah"}}),_c('v-radio',{attrs:{"label":"Menikah","value":"Menikah"}})],1)]}}])})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"4","sm":"12"}},[_c('label',[_vm._v("Agama")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"6","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Agama"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.listReligion,"error-messages":errors,"menu-props":"auto","solo":""},model:{value:(_vm.formBody.religion),callback:function ($$v) {_vm.$set(_vm.formBody, "religion", $$v)},expression:"formBody.religion"}})]}}])})],1)],1)],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("NPWP")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"NPWP"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('##.###.###.#-###.###'),expression:"'##.###.###.#-###.###'"}],attrs:{"type":"text","error-messages":errors,"solo":""},model:{value:(_vm.formBody.npwp),callback:function ($$v) {_vm.$set(_vm.formBody, "npwp", $$v)},expression:"formBody.npwp"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("No. Telepon")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"No. Telepon","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('#############'),expression:"'#############'"}],attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.telephone),callback:function ($$v) {_vm.$set(_vm.formBody, "telephone", $$v)},expression:"formBody.telephone"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("No. Rek Bank BJB")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"No. Rek Bank BJB","rules":"numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"solo":""},model:{value:(_vm.formBody.account_bank.bank_account_number),callback:function ($$v) {_vm.$set(_vm.formBody.account_bank, "bank_account_number", $$v)},expression:"formBody.account_bank.bank_account_number"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Nama Akun Bank BJB")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nama Akun Bank BJB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"solo":""},model:{value:(_vm.formBody.account_bank.bank_account_name),callback:function ($$v) {_vm.$set(_vm.formBody.account_bank, "bank_account_name", $$v)},expression:"formBody.account_bank.bank_account_name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Cabang Bank BJB")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Cabang Bank BJB"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"type":"text","error-messages":errors,"solo":""},model:{value:(_vm.formBody.account_bank.bank_branch),callback:function ($$v) {_vm.$set(_vm.formBody.account_bank, "bank_branch", $$v)},expression:"formBody.account_bank.bank_branch"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Divisi")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Divisi"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.divisiList,"error-messages":errors,"return-object":true,"item-value":"id","item-text":"name_satuan_kerja","menu-props":"auto","solo":""},on:{"change":_vm.handleChangeDivisi},model:{value:(_vm.formBody.divisiObject),callback:function ($$v) {_vm.$set(_vm.formBody, "divisiObject", $$v)},expression:"formBody.divisiObject"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Jabatan")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Jabatan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.jabatanList,"error-messages":errors,"return-object":true,"item-value":"id","item-text":"name_jabatan","menu-props":"auto","solo":""},on:{"change":_vm.handleChangeJabatan},model:{value:(_vm.formBody.jabatanObject),callback:function ($$v) {_vm.$set(_vm.formBody, "jabatanObject", $$v)},expression:"formBody.jabatanObject"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Kategori Pengelola")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Kategori Pengelola"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.managerCategory,"error-messages":errors,"menu-props":"auto","clearable":"","solo":""},model:{value:(_vm.formBody.manager_category),callback:function ($$v) {_vm.$set(_vm.formBody, "manager_category", $$v)},expression:"formBody.manager_category"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Tipe Menu")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Tipe Menu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"items":_vm.typeMenuList,"error-messages":errors,"return-object":false,"item-value":"id","item-text":"menu_type","menu-props":"auto","solo":""},model:{value:(_vm.formBody.menu),callback:function ($$v) {_vm.$set(_vm.formBody, "menu", $$v)},expression:"formBody.menu"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Alamat Sesuai KTP")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Alamat Saat ini"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.id_card_address),callback:function ($$v) {_vm.$set(_vm.formBody, "id_card_address", $$v)},expression:"formBody.id_card_address"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Alamat Saat ini")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Alamat Saat ini"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.current_address),callback:function ($$v) {_vm.$set(_vm.formBody, "current_address", $$v)},expression:"formBody.current_address"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Tanggal Bergabung")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Tanggal Bergabung"}},[_c('input-date-picker',{attrs:{"format-date":_vm.formatDateTime,"date-value":_vm.formBody.join_date,"value-date":_vm.formBody.join_date},on:{"update:valueDate":function($event){return _vm.$set(_vm.formBody, "join_date", $event)},"update:value-date":function($event){return _vm.$set(_vm.formBody, "join_date", $event)},"changeDate":function($event){_vm.formBody.join_date = $event}}})],1)],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"3","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Apakah Admin ?")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"9","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Apakah Admin ?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"error-messages":errors,"inset":""},model:{value:(_vm.formBody.is_admin),callback:function ($$v) {_vm.$set(_vm.formBody, "is_admin", $$v)},expression:"formBody.is_admin"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"3","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Pegawai Aktif ?")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"9","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Pegawai Aktif ?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"error-messages":errors,"inset":""},model:{value:(_vm.formBody.is_active),callback:function ($$v) {_vm.$set(_vm.formBody, "is_active", $$v)},expression:"formBody.is_active"}})]}}])})],1)],1),(!_vm.formBody.is_active)?_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{class:_vm.formBody.is_active ? '':'required'},[_vm._v("Tanggal Pengunduran Diri")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Tanggal Pengunduran Diri"}},[_c('input-date-picker',{attrs:{"format-date":'YYYY-MM-DD HH:MM:SS',"date-value":_vm.formBody.resign_date,"value-date":_vm.formBody.resign_date,"required":_vm.formBody.is_active ? false:true},on:{"update:valueDate":function($event){return _vm.$set(_vm.formBody, "resign_date", $event)},"update:value-date":function($event){return _vm.$set(_vm.formBody, "resign_date", $event)},"changeDate":function($event){_vm.formBody.resign_date = $event}}})],1)],1)],1):_vm._e(),(!_vm.formBody.is_active)?_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Alasan Pengunduran Diri")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Alasan Pengunduran Diri"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.reason_resignation),callback:function ($$v) {_vm.$set(_vm.formBody, "reason_resignation", $$v)},expression:"formBody.reason_resignation"}})]}}],null,false,2977988482)})],1)],1):_vm._e(),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.isLoading},on:{"click":_vm.handleUpdate}},[_vm._v(" Simpan ")])],1)],1)],2)],1)],1),_c('v-col',{staticClass:"pt-0",attrs:{"cols":"12","md":"4"}},[_c('foto-profile-user',{attrs:{"photo":_vm.formBody.photo,"avatar-update":_vm.formBody.photo},on:{"update:avatarUpdate":function($event){return _vm.$set(_vm.formBody, "photo", $event)},"update:avatar-update":function($event){return _vm.$set(_vm.formBody, "photo", $event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }