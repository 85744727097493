<template>
  <v-app>
    <toast-notification />
    <router-view />
  </v-app>
</template>

<script>
  export default {
    name: 'App',
  }
</script>
