var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"70%"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-7"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Nama Menu")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nama Menu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.title),callback:function ($$v) {_vm.$set(_vm.formBody, "title", $$v)},expression:"formBody.title"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Icon Menu")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Icon Menu"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.icon),callback:function ($$v) {_vm.$set(_vm.formBody, "icon", $$v)},expression:"formBody.icon"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Url Menu")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Url Menu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.furl),callback:function ($$v) {_vm.$set(_vm.formBody, "furl", $$v)},expression:"formBody.furl"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Urutan Menu")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Urutan Menu","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.seq),callback:function ($$v) {_vm.$set(_vm.formBody, "seq", _vm._n($$v))},expression:"formBody.seq"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"2","sm":"12"}},[_c('label',[_vm._v("Menu Aktif ?")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint. smAndDown},attrs:{"cols":"12","md":"10","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Menu Aktif ?"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-switch',{staticClass:"ma-0 pa-0",attrs:{"error-messages":errors,"inset":""},model:{value:(_vm.formBody.enable),callback:function ($$v) {_vm.$set(_vm.formBody, "enable", $$v)},expression:"formBody.enable"}})]}}])})],1)],1),_c('v-card-actions',[_c('v-col',[_c('v-btn',{staticClass:"mr-5",attrs:{"block":""},on:{"click":_vm.handleCancel}},[_vm._v(" Batal ")])],1),_c('v-col',[_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.isLoading},on:{"click":_vm.handleSave}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }