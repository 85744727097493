var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"70%"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('v-card',{staticClass:"pa-7"},[_c('validation-observer',{ref:"observer"},[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"3","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("Nama Berkas")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"9 ","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nama Berkas","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.file_name),callback:function ($$v) {_vm.$set(_vm.formBody, "file_name", $$v)},expression:"formBody.file_name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"3","sm":"12"}},[_c('label',[_vm._v("Nomor Berkas (jika ada)")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"9","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Nomor Berkas"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.file_number),callback:function ($$v) {_vm.$set(_vm.formBody, "file_number", $$v)},expression:"formBody.file_number"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"3","sm":"12"}},[_c('label',[_vm._v("Catatan")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"9","sm":"12"}},[_c('validation-provider',{attrs:{"name":"Catatan"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"error-messages":errors,"solo":""},model:{value:(_vm.formBody.note),callback:function ($$v) {_vm.$set(_vm.formBody, "note", $$v)},expression:"formBody.note"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{class:{'center py-4': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"3","sm":"12"}},[_c('label',{staticClass:"required"},[_vm._v("File")])]),_c('v-col',{class:{'py-0 pb-3': _vm.$vuetify.breakpoint.smAndDown},attrs:{"cols":"12","md":"9","sm":"12"}},[_c('validation-provider',{attrs:{"name":"File","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [(_vm.linkFile)?_c('v-btn',{attrs:{"icon":"","href":_vm.linkFile,"target":"_blank","color":"blue"}},[_c('v-icon',[_vm._v("mdi-link-variant")]),_vm._v(" "+_vm._s(_vm.linkFile)+" ")],1):_vm._e(),_c('v-file-input',{attrs:{"error-messages":errors,"show-size":"","label":"File input"},model:{value:(_vm.formBody.file),callback:function ($$v) {_vm.$set(_vm.formBody, "file", $$v)},expression:"formBody.file"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-btn',{staticClass:"mr-5",attrs:{"block":""},on:{"click":_vm.handleCancel}},[_vm._v(" Batal ")])],1),_c('v-col',{attrs:{"cols":"12","md":"6","sm":"12"}},[_c('v-btn',{attrs:{"color":"primary","block":"","loading":_vm.isLoading},on:{"click":_vm.handleSave}},[_vm._v(" Simpan ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }